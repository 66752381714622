import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-10">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        
        {/* Logo and Contact Info */}
        <div className="flex flex-col justify-center items-center">
          <p className="mb-2">Phone: +91 97524 11120</p>
          <p className="mb-2">Email: bhandarirepository2003@gmail.com</p>
          <p className="mb-2 w-[450px] text-center  ">Address: 128 S.R Compound, Lasudiya Mori, Devas Naka, Mangalya , Inodre, Madhya Pardesh</p>
        </div>

        {/* Links */}
        <div className="flex flex-col items-center">
          <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
          <ul className="text-center">
            <li className="mb-2 hover:text-gray-400"><Link to='/'>Home</Link></li>
            <li className="mb-2 hover:text-gray-400"><Link to="/about">About Us</Link></li>
            <li className="mb-2 hover:text-gray-400"><Link to="/service">Services</Link></li>
            <li className="mb-2 hover:text-gray-400"><Link to="/gallery">Gallery</Link></li>
            <li className="mb-2 hover:text-gray-400"><Link to="/contact">Contact Us</Link></li>
          </ul>
        </div>

        {/* Social Media Icons */}
        <div className="flex flex-col items-center">
          <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
          <div className="flex space-x-4">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebookF className="text-2xl hover:text-gray-400 transition duration-500 hover:scale-125" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="text-2xl hover:text-gray-400 transition duration-500 hover:scale-125" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
