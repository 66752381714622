import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Gallery() {
  const imageCount = 22; // Change to the actual number of images
  const images = Array.from(
    { length: imageCount },
    (_, index) => `/Gallery/warehouse (${index + 1}).jpg`
  );

  return (
    <div className="w-full relative overflow-x-hidden">
      <div className="w-full pt-[20px] pb-[150px] relative z-0 top-[5rem] flex flex-col gap-[30px] justify-center items-center overflow-x-hidden">
        <div className="flex gap-3 flex-col justify-center items-center">
          <h1 className="text-[35px] font-bold font-serif">Gallery</h1>
          <p className="w-[60%] text-center text-gray-500 text-[15px]">
          Welcome to the Bhandari Repository Gallery, where we showcase the heart of our operations. From our state-of-the-art warehouses and efficient inventory management systems to the dedicated team driving our success, take a glimpse into our journey of delivering excellence in logistics.
          </p>
        </div>

        <div className="w-[70%] h-[600px] p-5 pb-6 overflow-y-auto hide-scrollbar ">
          <div className="w-[100%] h-[100%] flex flex-wrap justify-around items-center gap-9">
            {images.map((src, index) => (
              <div className="w-[300px] h-[300px]">
                <img
                  key={index}
                  src={src}
                  alt={`Gallery ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full">
        <Footer/>
      </div>
    </div>
  );
}

export default Gallery;
